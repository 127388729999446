import React from 'react'
// project imports
import styles from './password-strength.styles.css'
import moment from 'moment'
// sofe imports
import { CprLoader } from 'canopy-styleguide!sofe'

const visualMapping = {
  'Weak': 'Too weak',
  'Fair': 'Weak',
  'Good': 'Medium',
  'Strong': 'Good',
  'Very Strong': 'Very strong'
}

const cssMapping = {
  'Weak': `${styles['Too-weak']}`,
  'Fair': `${styles['Fair'] }`,
  'Good': `${styles['Medium'] }`,
  'Strong': `cps-primary-green`,
  'Very Strong': `cps-primary-green`,
}

export default class PasswordStrength extends React.Component {

  render () {
    return (
      <span>
        {
          this.props.loadingPasswordStrength ? (
            <CprLoader />
          ) : (
            this.getContents()
          )
        }
      </span>
    )
  }

  getContents = () => {
    let strength = null, crackTime = null
    if (this.props.strength) {
      strength = (
        <span className={`${cssMapping[this.props.strength] || 'cps-light-gray'}`}>
          {visualMapping[this.props.strength] || 'Unknown'}
        </span>
      )
    }
    if (this.props.crackTimeSeconds !== undefined) {
      crackTime = (
        <span
          className={`cps-padding-left-4 ${styles.condensedFontToFit}`}
        >
          This password could be cracked in about {moment.duration(this.props.crackTimeSeconds, 'seconds').humanize()}
        </span>
      )
    }
    return (
      <>
        {strength}
        {crackTime}
      </>
    )
  }
}
