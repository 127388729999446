import React from 'react'
import IconInput from '../../icon-input/icon-input.component.js'

class PasswordIconInput extends React.Component {
  state = {
    passwordVisible: false,
  }

  render () {
    const visible = {
      name: 'visible',
      className: 'cps-color-primary-text',
      onClick: this.hidePassword,
    }
    const hidden = {
      name: 'hidden',
      className: 'cps-color-primary-text',
      onClick: this.showPassword,
    }

    const { forwardedRef, ...rest} = this.props
    return (
      <IconInput
        ref={forwardedRef}
        leftIcon={null}
        rightIcon={this.state.passwordVisible ? visible : hidden}
        type={this.state.passwordVisible ? 'text' : 'password'}
        autoCapitalize='none'
        {...rest}
      />
    )
  }

  hidePassword = () => {
    this.setState({passwordVisible: false})
  }

  showPassword = () => {
    this.setState({passwordVisible: true})
  }
}

export default React.forwardRef((props, ref) => (
  <PasswordIconInput {...props} forwardedRef={ref}/>
))
