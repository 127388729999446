import React from 'react'
import passwordStyles from '../password.styles.css'
import InputDivWrapper from '../../input-div-wrapper/input-div-wrapper.component.js'
import PasswordIconInput from '../password-icon-input/password-icon-input.component.js'
import RequiredField from 'src/form/required-field.component.js'

export default class ConfirmPasswordField extends React.Component {

  state = {
    showConfirmErrors: false,
    invalidConfirmPassword: true,
    passwordConfirm: '',
    passwordVisible: false,
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.password !== this.props.password) {
      this.passwordChange()
    }
  }

  render () {
    const { showErrors, password } = this.props
    return (
      <InputDivWrapper
      showError={(showErrors || this.state.showConfirmErrors) && this.state.invalidConfirmPassword}
      >
        <PasswordIconInput
          value={this.state.passwordConfirm}
          placeholder={'Re-enter your password'}
          className='cps-form-control'
          aria-label='Password Confirm'
          onChange={this.updatePasswordConfirm}
          onBlur={this.showConfirmErrors}
          tabIndex={this.props.noTabIndex ? undefined : 3}
        />
        <span className={`cps-error-block ${passwordStyles.absolute}`} aria-hidden={`${!((showErrors || this.state.showConfirmErrors) && this.state.invalidConfirmPassword)}`}>
          {
            this.state.passwordConfirm.length === 0 &&
            'The password confirm field is required'
          }
          {
            this.state.passwordConfirm.length !== 0 &&
            'The password must match'
          }
        </span>
      </InputDivWrapper>
    )
  }

  passwordChange = () => {
    const arePasswordsTheSame = this.props.password === this.state.passwordConfirm
    this.setState({invalidConfirmPassword: !arePasswordsTheSame})
  }

  updatePasswordConfirm = (e) => {
    const newPasswordValue = e.target.value
    const arePasswordsTheSame = this.props.password === newPasswordValue
    this.setState({passwordConfirm: newPasswordValue, invalidConfirmPassword: !arePasswordsTheSame}, this.props.notifyInputChange)
  }

  resetConfirmPasswordErrors = () => {
    this.setState({showConfirmErrors: false})
  }

  showConfirmErrors = () => {
    this.setState({showConfirmErrors: true})
  }

  getPasswordConfirmValue = () => {
    return this.state.passwordConfirm
  }

  getInvalidConfirm = () => {
    return this.state.invalidConfirmPassword
  }
}
