import React from 'react'
import { string, node, oneOfType } from 'prop-types';
import { asyncStacktrace, catchSyncStacktrace } from 'auto-trace'
// project imports
import InputDivWrapper from '../input-div-wrapper/input-div-wrapper.component.js'
import { checkPasswordStrength } from './password.resource.js'
import PasswordErrorsAndInfo from './password-errors-and-info-block/password-errors-and-info-block.component.js'
import CanopyPasswordField from './canopy-password-field/canopy-password-field.component.js'
import ConfirmPasswordField from './confirm-password-field/confirm-password-field.component.js'
import PasswordIconInput from './password-icon-input/password-icon-input.component.js'

import { fromEvent } from 'rxjs'
import { tap, debounceTime, filter, pluck, switchMap  } from 'rxjs/operators'

export default class Password extends React.Component {

  state = {
    password: '',
    invalidPassword: true,
    showPasswordErrors: false,
    loadingPasswordStrength: false,
    passwordStrength: undefined,
    crackTimeSeconds: undefined,
    enforcePasswordStrength: true,
  }

  static propTypes = {
    passwordLabel: oneOfType([string, node]),
    confirmLabel: oneOfType([string, node]),
    canopyLabel: oneOfType([string, node]),
  }

  static defaultProps = {
    showConfirmPassword: false,
    showCanopyPassword: false,
    showHelperText: false,
    showErrors: false,
    showPasswordStrength: false,
    /* We have grandfathered passwords of 8 or 9 characters in favor of >=10 characters,
     * but you can still sign in with those old passwords. So 8 is the default.
     */
    minPasswordLength: 8,
    maxPasswordLength: 100,
  }

  componentDidMount () {
    if (this.props.showPasswordStrength) {
      const mainPasswordValue$ =
        fromEvent(this.mainPassField, 'input')
        .pipe(
          pluck('target', 'value'),
          tap(value => {
            this.updatePassword(value)
          }),
          debounceTime(250),
          filter((value) => (value.length >= this.props.minPasswordLength && value.length <= this.props.maxPasswordLength )),
          tap(value => {
            this.setState({loadingPasswordStrength: true})
          }),
          switchMap(value => (checkPasswordStrength(value)))
        )

      this.subscription = mainPasswordValue$
        .subscribe(
          (result) => {
            this.setState({
              loadingPasswordStrength: false,
              passwordStrength: result.strength,
              enforcePasswordStrength: true,
              crackTimeSeconds: result.crackTimeSeconds
            }, () => {
              const invalidPassword = !this.isPasswordValid(this.state.password)
              this.setState({invalidPassword}, this.notifyInputChange)
            })
          },
          asyncStacktrace(err => {
            this.setState({
              loadingPasswordStrength: false,
              passwordStrength: undefined,
              enforcePasswordStrength: false
            })
            catchSyncStacktrace(err)
          })
        )
    }
  }

  componentWillUnmount () {
    if (this.subscription && this.subscription.unsubscribe) {
      this.subscription.unsubscribe()
    }
  }

  render () {
    const { passwordLabel, confirmLabel, canopyLabel } = this.props;
    return (
      <div>
        {passwordLabel && (
          <div className="cps-padding-bottom-8">{passwordLabel}</div>
        )}
        {this.passwordField()}
        {
          this.props.showConfirmPassword &&
            <>
              {confirmLabel && (
                <div className="cps-padding-bottom-8">{confirmLabel}</div>
              )}
              <ConfirmPasswordField
                ref={el => this.confirmPasswordField = el}
                showErrors={this.props.showErrors}
                notifyInputChange={this.notifyInputChange}
                password={this.state.password}
                noTabIndex={this.props.noTabIndex}
              />
            </>
        }
        {
          this.props.showCanopyPassword &&
            <>
              {canopyLabel && (
                <div className="cps-padding-bottom-8">{canopyLabel}</div>
              )}
              <CanopyPasswordField
                ref={el => this.canopyPasswordField = el}
                noTabIndex={this.props.noTabIndex}
                showErrors={this.props.showErrors}
                notifyInputChange={this.notifyInputChange}
              />
            </>
        }
      </div>
    )
  }
  passwordField = () => {
    return (
      <InputDivWrapper
        showError={(this.props.showErrors || this.state.showPasswordErrors) && this.state.invalidPassword}
      >
        <PasswordIconInput
          id="canopy-login-ui-password"
          ref={el => this.mainPassField = el}
          value={this.state.password}
          placeholder={'Enter your password'}
          className={`cps-form-control`}
          aria-label='Password'
          onChange={this.maybeUpdatePassword}
          onBlur={this.showPasswordErrors}
          tabIndex={this.props.noTabIndex ? undefined : 2}
        />
        <PasswordErrorsAndInfo
          {...this.props}
          {...this.state}
        >
          {this.props.children}
        </PasswordErrorsAndInfo>
      </InputDivWrapper>
    )
  }

  resetPassword = () => {
    if (this.canopyPasswordField) this.canopyPasswordField.resetCanopyPasswordErrors()
    if (this.confirmPasswordField) this.confirmPasswordField.resetConfirmPasswordErrors()
    this.setState({password: '', invalidPassword: true, showPasswordErrors: false}, this.passwordResetCallback)
  }

  getEncodedPassword = () => {
    return window.btoa(this.state.password)
  }

  getIsPasswordValid = () => {
    return !this.state.invalidPassword &&
      (this.props.showCanopyPassword ? this.canopyPasswordField.getCanopyPassword().length >= 1 : true)
  }

  getIsPasswordAndConfirmValid = () => {
    return !this.state.invalidPassword &&
      !this.confirmPasswordField.getInvalidConfirm() &&
      (this.props.showCanopyPassword ? this.canopyPasswordField.getCanopyPassword().length >= 1 : true)
  }

  showPasswordErrors = (e) => {
    const value = e.target.value
    const isValid = this.isPasswordValid(value)
    this.setState({showPasswordErrors: !isValid})
  }

  getCanopyPassword = () => {
    return this.canopyPasswordField && this.canopyPasswordField.getCanopyPassword()
  }

  maybeUpdatePassword = (e) => {
    const newPasswordValue = e.target.value
    if (!this.props.showPasswordStrength || !this.state.enforcePasswordStrength) {
      this.updatePassword(newPasswordValue)
    }
  }

  updatePassword = (newPasswordValue) => {
    const newState = {password: newPasswordValue}
    if (this.props.showConfirmPassword) {
      this.confirmPasswordField.passwordChange()
    }
    if (!this.props.showPasswordStrength || !this.state.enforcePasswordStrength) {
      const invalidPassword = !this.isPasswordValid(newPasswordValue)
      newState.invalidPassword = invalidPassword
    } else {
      newState.invalidPassword = true
    }
    if (newPasswordValue.length < this.props.minPasswordLength || newPasswordValue.length > this.props.maxPasswordLength) {
      newState.passwordStrength = undefined
      newState.loadingPasswordStrength = false
      newState.crackTimeSeconds = undefined
    }
    this.setState(newState, this.notifyInputChange)
  }

  isPasswordValid = (password) => {
    const passwordValidLength = (password.length >= this.props.minPasswordLength && password.length <= this.props.maxPasswordLength)
    return passwordValidLength
    // Turning off the passwordStrength hook
    // if (!this.props.showPasswordStrength || !this.state.enforcePasswordStrength) {
    //  return passwordValidLength
    // } else {
    //  return passwordValidLength && this.state.passwordStrength !== 'Weak'
    // }
  }

  passwordResetCallback = () => {
    if (this.props.onPasswordReset) {
      this.props.onPasswordReset()
    } else {
      this.notifyInputChange()
    }
  }

  notifyInputChange = () => {
    if (this.props.onInputChange) {
      this.props.onInputChange()
    }
  }
}
