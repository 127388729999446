import { fetchAsObservable } from 'fetcher!sofe'

export function checkPasswordStrength (planTextPassword) {

  const body = {
    password: window.btoa(planTextPassword)
  }

  return fetchAsObservable(`/password-strength`, {
    method: 'post',
    body
  })

}
