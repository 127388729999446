exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-icon-input-icon-input-styles__leftIcon {\n  left: 12px;\n  top: 0;\n  position: absolute;\n  z-index: 2;\n  padding-top: 4px;\n}\n\n.src-icon-input-icon-input-styles__rightIcon {\n  right: 12px;\n  top: 0;\n  position: absolute;\n  z-index: 2;\n  padding-top: 4px;\n  color: var(--cps-color-primary-text);\n}\n\n.src-icon-input-icon-input-styles__withLeftIcon {\n  padding-left: 42px;\n}\n\n.src-icon-input-icon-input-styles__withRightIcon {\n  padding-right: 42px;\n}\n\n.src-icon-input-icon-input-styles__mobileInput > input {\n  height: 48px;\n  font-size: 16px;\n  padding-top: 0px;\n  padding-bottom: 0px;\n  font-style: normal;\n}\n\n.src-icon-input-icon-input-styles__mobileInput > input::-ms-input-placeholder {\n  font-style: normal;\n}\n\n.src-icon-input-icon-input-styles__mobileInput > input::placeholder {\n  font-style: normal;\n}\n\n.src-icon-input-icon-input-styles__mobileHeight {\n  height: 48px;\n}\n\n.src-icon-input-icon-input-styles__mobileIcon {\n  padding-top: 12px;\n}\n\ninput:focus + .src-icon-input-icon-input-styles__mobileIcon > cps-icon {\n  color: var(--cps-color-primary)!important;\n}", ""]);

// Exports
exports.locals = {
	"leftIcon": "src-icon-input-icon-input-styles__leftIcon",
	"rightIcon": "src-icon-input-icon-input-styles__rightIcon",
	"withLeftIcon": "src-icon-input-icon-input-styles__withLeftIcon",
	"withRightIcon": "src-icon-input-icon-input-styles__withRightIcon",
	"mobileInput": "src-icon-input-icon-input-styles__mobileInput",
	"mobileHeight": "src-icon-input-icon-input-styles__mobileHeight",
	"mobileIcon": "src-icon-input-icon-input-styles__mobileIcon"
};