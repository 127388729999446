exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-password-password-strength-password-strength-styles__Too-weak {\n  color: #FF345E;\n}\n\n.src-password-password-strength-password-strength-styles__Fair {\n  color: #FF345E;\n}\n\n.src-password-password-strength-password-strength-styles__Medium {\n  color: #EC8144;\n}\n\n.src-password-password-strength-password-strength-styles__condensedFontToFit {\n  line-height: 14px;\n  padding-top: 2px;\n  position: absolute;\n}\n", ""]);

// Exports
exports.locals = {
	"Too-weak": "src-password-password-strength-password-strength-styles__Too-weak",
	"Fair": "src-password-password-strength-password-strength-styles__Fair",
	"Medium": "src-password-password-strength-password-strength-styles__Medium",
	"condensedFontToFit": "src-password-password-strength-password-strength-styles__condensedFontToFit"
};