import React from 'react'
import styles from './input-div-wrapper.styles.css'
import { MobileContext } from 'src/mobile/mobile-context.js'

function InputDivWrapper (props) {
  const margin = props.mobile ? 'cps-margin-bottom-24' : 'cps-margin-bottom-32'
  const errorClass = props.showError ? 'cps-has-error' : ''
  const mobileClasses = props.mobile ? styles.mobileInput : ''
  const permanentClasses = `cps-form-group ${margin} cps-has-feedback ${errorClass} ${mobileClasses}`
  const classes = props.classes ? `${permanentClasses} ${props.classes}` : permanentClasses
  return (
    <div
      className={classes}
    >
      {props.children}
    </div>
  )
}

export default function InputDivWrapperContextConsumer (props) {
  return (
    <MobileContext.Consumer>
      {value => (<InputDivWrapper {...props} {...value}/>)}
    </MobileContext.Consumer>
  )

}
