import React from 'react'
import PasswordStrength from '../password-strength/password-strength.component.js'
import styles from './password-errors-and-info-block.styles.css'
import moment from 'moment'

export default class PasswordErrorsAndInfoBlock extends React.Component {

  render () {
    const {
      showPasswordErrors,
      showErrors,
      invalidPassword,
      password,
      crackTimeSeconds,
    } = this.props
    const helpBlocks = this.getCpsHelpBlocks(this.props)
    return (
      <div>
        {
          helpBlocks.filter(item => !!item).length > 0 &&
          <span className={`cps-help-block ${styles.padChildren}`}>
            {
              helpBlocks
            }
          </span>
        }
        {
          /*
           * This is always in the dom, but the input field will only show it when cps-has-error is one of the classes
           * It is hidden using aria-hidden from screenreaders
          */
        }
        {
          crackTimeSeconds === undefined &&
            <span
              className={`cps-error-block ${styles.absolute}`} 
              aria-hidden={`${!((showErrors || showPasswordErrors) && invalidPassword)}`}
            >
              {
                password.length === 0 ?
                  'The password field is required' :
                  `The password must be between ${this.props.minPasswordLength} and ${this.props.maxPasswordLength} characters`
              }
            </span>
        }
        {
          (!showErrors && !showPasswordErrors && invalidPassword) &&
            this.props.children
        }
      </div>
    )
  }

  getCpsHelpBlocks = (props) => {
    const {
      showPasswordStrength,
      passwordStrength,
      showHelperText,
      showPasswordErrors,
      showErrors,
      invalidPassword,
      loadingPasswordStrength,
      password,
      crackTimeSeconds
    } = this.props

    return [
      showPasswordStrength && (loadingPasswordStrength || passwordStrength) && (
        <PasswordStrength
          key='passwordStrength'
          loadingPasswordStrength={loadingPasswordStrength}
          strength={passwordStrength}
          crackTimeSeconds={crackTimeSeconds}
        />
      ),
      showHelperText && !showPasswordErrors && crackTimeSeconds === undefined && (
        /*
          most of the time the helper text will be visible. It's only ever not visible when there is an error/helper state,
          of which there are two showPasswordErrors && crackTimeSeconds
          */
        <span key={'helperText'} className={`cps-light-gray ${styles.absolute}`}>Minimum of {this.props.minPasswordLength} characters in length</span>
      ),
    ]
  }

}

