import React from 'react'
import passwordStyles from '../password.styles.css'
import InputDivWrapper from '../../input-div-wrapper/input-div-wrapper.component.js'
import PasswordIconInput from '../password-icon-input/password-icon-input.component.js'

export default class CanopyPasswordField extends React.Component {

  state = {
    canopyPassword: localStorage.getItem('login-page:canopy-password') || '', 
    showCanopyPasswordErrors: false,
    passwordVisible: false,
  }

  render () {
    const { noTabIndex, showErrors, notifyInputChange } = this.props
    return (
      <InputDivWrapper
        showError={(this.props.showErrors || this.state.showCanopyPasswordErrors) && this.state.canopyPassword.length < 1}
      >
        <PasswordIconInput
          id="login-ui-password"
          value={this.state.canopyPassword}
          placeholder={'Enter the Canopy Password'}
          label={'Canopy Password'}
          className='cps-form-control'
          aria-label='Canopy Password'
          onChange={this.updateCanopyPassword}
          onBlur={this.showCanopyPasswordErrors}
          tabIndex={noTabIndex ? undefined : 4}
        />
        {
          this.state.canopyPassword.length === 0 &&
            <span className={`cps-error-block ${passwordStyles.absolute}`}>
              The Canopy password field is required
            </span>
        }
      </InputDivWrapper>
    )
  }

  showCanopyPasswordErrors = () => {
    this.setState({showCanopyPasswordErrors: true})
  }

  updateCanopyPassword = (e) => {
    const newPasswordValue = e.target.value
    const showCanopyPasswordErrors = !newPasswordValue.length >=1
    this.setState({canopyPassword: newPasswordValue, showCanopyPasswordErrors}, () => {
      localStorage.setItem('login-page:canopy-password', newPasswordValue)
      this.props.notifyInputChange()
    })
  }

  resetCanopyPasswordErrors = () => {
    this.setState({showCanopyPasswordErrors: false})
  }

  getCanopyPassword = () => {
    return this.state.canopyPassword
  }
}
