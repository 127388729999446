import React from 'react'
import { CprIcon } from 'canopy-styleguide!sofe'
import { MobileContext } from 'src/mobile/mobile-context.js'
import styles from './icon-input.styles.css'

function IconInput(props) {
  const {
    leftIcon,
    rightIcon,
    className,
    mobile,
    forwardedRef,
    id,
    ...rest
  } = props

  return (
    <div className={mobile ? styles.mobileInput : undefined} style={{position: 'relative'}}>
      <input
        id={id || undefined}
        ref={forwardedRef}
        className={`${className} ${leftIcon ? styles.withLeftIcon : ''} ${rightIcon ? styles.withRightIcon : ''}`}
        {...rest}
      />
      {
        rightIcon && rightIcon.name && (
          <div
            className={`${styles.rightIcon} ${mobile ? `${styles.mobileHeight} ${styles.mobileIcon}` : ''}`}
            onClick={handleRight}
          >
            <CprIcon
              className={rightIcon.className}
              name={rightIcon.name}
            />
          </div>
        )
      }
    </div>
  )

  function handleRight () {
    const { rightIcon } = props
    if (rightIcon && rightIcon.onClick) {
      rightIcon.onClick()
    }
  }
}

export default React.forwardRef((props, ref) => (
  <MobileContext.Consumer>
    {value => (<IconInput {...props} {...value} forwardedRef={ref}/>)}
  </MobileContext.Consumer>
))

